































export default {
	props: {
		stats: { required: true },
		questionsCount: { required: true }
	}
}
